/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';
import withRoleRequired from '../lib/withRoleRequired';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { MarkdownQueryData } from '../types/Global';

interface Props {
  data: MarkdownQueryData;
}

const SitePageTemplate: React.FunctionComponent<Props> = ({ data }) => {
  if (!data || !data.markdownRemark) return null;
  const { frontmatter, html } = data.markdownRemark
  return (
    <Layout pageHeader={frontmatter.title}>
      <SEO title={frontmatter.title} />
      <div className="max-w-4xl px-4 py-4 mx-auto md:px-8 md:py-4">
        <div dangerouslySetInnerHTML={{ __html: `${html}` }} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String) {
    markdownRemark(id: {eq: $id}, frontmatter: { type: {eq: "Site"} }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`

export default withRoleRequired(SitePageTemplate);
